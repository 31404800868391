import React from "react"
import styles from "./footer.module.css"
import indexStyle from "../../pages/index.module.css"
import Img from "gatsby-image"
import buttonStyles from "../../styles/buttons.module.css"
import { Link } from "gatsby"

export default function Footer({images}) {
    return (
      <div className={styles.main}>
          <div className={styles.footer}>
              <div className={styles.left}>
                  <div className={styles.leftLink}>
                      <Link id="cookies-page" to="/#contact">Contact Us</Link>
                  </div>
                  <div className={styles.leftLink}>
                      <Link id="cookies-page" to="/#faq">FAQ</Link>
                  </div>

                  <div className={styles.leftLink}>
                      <Link id="cookies-page" to="/cookies">Cookies</Link>
                  </div>
                  <div className={styles.leftLink}>
                      <Link id="cookies-page" to="/terms">Terms and Conditions</Link>
                  </div>
                  <div className={styles.leftLink}>
                      <Link id="cookies-page" to="/privacy">Privacy Policy</Link>
                  </div>
              </div>
              <div className={styles.right}>
                  <div className={styles.rightTextOne}>LORI BOOKS</div>
                  <div className={styles.rightTextTwo}>Europe</div>
                  <div className={styles.rightSocial}>
                      <a href="https://www.facebook.com/boudoiralbums/" target="_blank" className={styles.socialIcon}>
                          <Img className={indexStyle.missionImage} objectFit="contain"
                               fluid={images.fbIcon.childImageSharp.fluid} alt="Lori Books Facebook"/>
                      </a>

                      <a href="https://www.instagram.com/boudoiralbums/" target="_blank" className={styles.socialIcon}>
                          <Img className={indexStyle.missionImage} objectFit="contain"
                               fluid={images.inIcon.childImageSharp.fluid} alt="Lori Books Instagram"/>
                      </a>

                      <a href="https://www.pinterest.com/boudoiralbums/" target="_blank" className={styles.socialIcon}>
                          <Img className={indexStyle.missionImage} objectFit="contain"
                               fluid={images.piIcon.childImageSharp.fluid} alt="Lori Books Pinterest"/>
                      </a>

                      <a href="https://twitter.com/boudoiralbums/" target="_blank" className={styles.socialIcon}>
                          <Img className={indexStyle.missionImage} objectFit="contain"
                               fluid={images.twIcon.childImageSharp.fluid} alt="Lori Books Twitter"/>
                      </a>
                  </div>
                  <div className={styles.rightCopyright}>© 2021 LORI BOOKS ALL RIGHTS RESERVED</div>
              </div>
          </div>
      </div>
    )
}
