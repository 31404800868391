import React from "react"
import { Link } from "gatsby"
import styles from "./menu.module.css"
import buttonStyles from "../styles/buttons.module.css"
import { SITE_URL } from "../config/url"

export default function Menu({ location }) {

    return (
      <div>
          <div className={styles.main}>
              <a href={SITE_URL} className={styles.logo}>
                  <img className={styles.logoImage} itemProp="image"
                       src="/images/logo.png"
                       alt="dark logo"/>
              </a>
              <div className={styles.spacer}/>

              <div className={styles.items}>
                  <div className={styles.menuItem}>
                      <Link to='/#cover-styles'>Cover Styles</Link>
                  </div>
                  <div className={styles.menuItem}>
                      <Link to='/#album-types'>Album Types</Link>
                  </div>
                  <div className={styles.menuItem}>
                      <Link to='/#album-details'>Album Details</Link>
                  </div>
                  <div className={styles.menuItem}>
                      <Link to='/#pricing'>Pricing</Link>
                  </div>
                  <div className={styles.menuItem}>
                      <Link to='/#gallery'>Gallery</Link>
                  </div>
                  <div className={styles.menuItem}>
                      <Link to='/#contact'>Contact Us</Link>
                  </div>

                  <div id="header-shop-button" className={styles.button}>
                      <Link to="https://shop.loribooks.com" className={buttonStyles.button}>ORDER NOW</Link>
                  </div>
              </div>
          </div>
      </div>
    )
}