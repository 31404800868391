import React from "react"

import Helmet from "react-helmet"
import BurgerMenu from "./burgerMenu"
import Menu from "./menu"
import { SITE_URL } from "../config/url"
import CookieConsent from "react-cookie-consent"

export default function Page({ title, children, location }) {
    return (
      <div id='home'>
          <Helmet>
              <html lang="en"/>
              <title>{title}</title>
              <meta name="title" content="Boudoir Personalized Lay Flat Photo Albums and Books | Lori Books"/>
              <meta name="robots" content='index,follow'/>
              <link rel="alternate" href={SITE_URL} hreflang="en-us"/>
              <meta name="description"
                    content="Stunning handmade lay flat Boudoir Albums and Books in a wide range of european leathers and fabrics. Send us your photos and we take care of everything."/>
              <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1"/>
              <meta property="og:title" content="Boudoir Personalized Lay Flat Photo Albums and Books | Lori Books"/>
              <meta property="og:site_name" content="Lori Books"/>
              <meta property="og:url" content={SITE_URL}/>
              <meta property="og:description"
                    content="Stunning handmade lay flat Boudoir Albums and Books in a wide range of european leathers and fabrics. Send us your photos and we take care of everything."/>
              <meta property="og:type" content="business.business"/>
              <meta property="og:image"
                    content={`${SITE_URL}images/og.jpg`}/>



              <meta name="ahrefs-site-verification"
                    content="bc9f9d0b39b43fd8947258af4d14a0f6fd39e6fd83e25898ff8363b94432134b"/>


          </Helmet>
          <BurgerMenu/>
          <Menu location={location}/>
          <CookieConsent
            enableDeclineButton
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            cookieName="CookieConsent"
            onAccept={()=>{console.log("ACCEPT")}}
            onDecline={()=>{console.log("DECLINE")}}
          >
              We are using cookies to give you the best experience on our site.
          </CookieConsent>
          {children}

      </div>
    )
}