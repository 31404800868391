import React, { useState } from "react"
import { slide as Menu } from "react-burger-menu"
import { Link } from "gatsby"

import styles from "./burgerMenu.module.css"
import menuStyles from "./menu.module.css"

export default function BurgerMenu() {

    const [isOpen, setIsOpen] = useState(false)

    return (
      <div className={styles.main}>
          <Menu isOpen={isOpen} onOpen={() => setIsOpen(true)} styles={
              {
                  bmBurgerButton: {
                      position: "absolute",
                      width: "18px",
                      height: "15px",
                      left: "30px",
                      top: "50px",
                      background: "black"
                  },
                  bmBurgerBars: {
                      background: "white"
                  },
                  bmBurgerBarsHover: {
                      background: "#a90000"
                  },
                  bmCrossButton: {
                      display: "none",
                      height: "24px",
                      width: "24px"
                  },
                  bmCross: {
                      background: "#bdc3c7"
                  },
                  bmMenuWrap: {
                      position: "fixed",
                      height: "100%",
                      width: "100%"
                  },
                  bmMenu: {
                      overflow: "auto",
                      background: "black",
                      padding: "2.5em 1.5em 0",
                      fontSize: "1.15em"
                  },
                  bmMorphShape: {
                      fill: "#373a47"
                  },
                  bmItemList: {
                      color: "#b8b7ad",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      border: 0
                  },
                  bmItem: {
                      display: "block",
                      marginBottom: "10px",
                      outline: "none"
                  },
                  bmOverlay: {
                      background: "rgba(0, 0, 0, 0.3)"
                  }
              }
          }>
              <img className={styles.logoImage} itemProp="image"
                   src="/images/logo.png"
                   alt="dark logo"/>
              <div className={menuStyles.menuItem} onClick={()=>setIsOpen(false)}>
                  <Link to='/#home'>Home</Link>
              </div>
              <div className={menuStyles.menuItem}  onClick={()=>setIsOpen(false)}>
                  <Link to='/#cover-styles'>Cover Styles</Link>
              </div>
              <div className={menuStyles.menuItem}  onClick={()=>setIsOpen(false)}>
                  <Link to='/#album-types'>Album Types</Link>
              </div>
              <div className={menuStyles.menuItem}  onClick={()=>setIsOpen(false)}>
                  <Link to='/#album-details'>Album Details</Link>
              </div>
              <div className={menuStyles.menuItem}  onClick={()=>setIsOpen(false)}>
                  <Link to='/#pricing'>Pricing</Link>
              </div>
              <div className={menuStyles.menuItem}  onClick={()=>setIsOpen(false)}>
                  <Link to='#/gallery'>Gallery</Link>
              </div>
              <div className={menuStyles.menuItem}  onClick={()=>setIsOpen(false)}>
                  <Link to='/#contact'>Contact Us</Link>
              </div>

              <div className={menuStyles.pushDown}/>
          </Menu>
      </div>
    )
}