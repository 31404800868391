import React, { useState } from "react"
import styles from "./goToTopButton.module.css"
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import * as classnames from "classnames"

export default function GoToTopButton() {
    const [isVisible, setIsVisible] = useState(false);

    useScrollPosition(({ prevPos, currPos }) => {
        if(prevPos.y <= 600 && currPos.y >600){
            setIsVisible(true);
        }

        if(prevPos.y >= 600 && currPos.y <600){
            setIsVisible(false);
        }

    }, [isVisible],null,true,1000)

    let className = classnames(styles.main, { [styles.hidden]: !isVisible })


    return (
      <a href={'/#home'} className={className}>
          <div className={styles.arrow}/>
      </a>
    )
}
